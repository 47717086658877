import { FC, useMemo } from 'react';

import { ResolutionType } from '@prisma/client';
import cn from 'classnames';

import { CustomizeData } from '@/app/(app-layout)/aibot/[chatbotId]/(layout)/customize/types';
import { useMessengerContext } from '@/context/messengerContext';
import { messengerStore } from '@/store/messenger';
import useTranslationStore from '@/store/translation';

import ChatIcon from '../icons/chat-icon.component';
import MessengerButton from './messenger-button.component';

type MessengerClosedBannerProps = {
  className?: string;
  closedBy: ResolutionType;
};
const MessengerClosedBanner: FC<MessengerClosedBannerProps> = ({
  className,
  closedBy,
}) => {
  const { chatbot, updateActiveChat } = useMessengerContext();

  const updateScreen = messengerStore((state) => state.updateScreen);

  const customize = useMemo(
    () => chatbot?.customize as CustomizeData,
    [chatbot],
  );

  const { getValue } = useTranslationStore();

  return (
    <div
      id="messenger-closed-banner"
      className={cn(
        'flex flex-col items-center justify-center border-b border-t border-[#EAEAEA] bg-[#F5F5F5] px-3 py-[30px] sm:px-[15px]',
        className,
      )}
    >
      <p
        className="mb-[12px] text-center text-[13px] leading-tight sm:text-[14px]"
        dangerouslySetInnerHTML={{
          __html:
            closedBy === ResolutionType.AUTOMATIC
              ? 'Conversation closed due to inactivity.<br class="block sm:hidden" />Start a new chat if you need help.'
              : getValue('conversationClosed'),
        }}
      />
      <MessengerButton
        backgroundColor={customize?.theme?.color?.userMessage}
        onClick={() => {
          updateActiveChat(null);
          updateScreen('messenger');
        }}
      >
        {getValue('newConversationBtn')}
        <ChatIcon className="ml-[6px]" />
      </MessengerButton>
    </div>
  );
};

export default MessengerClosedBanner;
