'use client';

import { FC, useMemo, useRef } from 'react';

import ChevronRightIcon from '@heroicons/react/24/outline/ChevronRightIcon';
import { MessageType } from '@prisma/client';
import cn from 'classnames';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import 'dayjs/locale/tr';
import relativeTime from 'dayjs/plugin/relativeTime';
import removeMd from 'remove-markdown';

import type { CustomizeData } from '@/app/(app-layout)/aibot/[chatbotId]/(layout)/customize/types';
import type { SettingsFormData } from '@/app/(app-layout)/aibot/[chatbotId]/(layout)/settings/form-wrapper';
import { useMessengerContext } from '@/context/messengerContext';
import { messengerStore } from '@/store/messenger';
import useTranslationStore from '@/store/translation';
import { getMessageSenderName, getMessageType } from '@/utils/message';
import { getLanguageCode } from '@/utils/translations';

import Avatar from '../avatar.component';
import AngularBubbleIcon from '../icons/angular-bubble-icon.component';
import PlaneIcon from '../icons/plane-icon.component';
import MessengerButton from './messenger-button.component';
import MessengerFooter from './messenger-footer.component';
import styles from './messenger.module.scss';

dayjs.extend(relativeTime);

type ConversationListProps = {};

const ConversationList: FC<ConversationListProps> = ({}) => {
  const conversationListRef = useRef<HTMLDivElement>(null);
  const { chatbot, updateActiveChat } = useMessengerContext();
  const { language, getValue } = useTranslationStore();
  const hasBranding = useMemo(() => {
    return chatbot.workspace.plan.hasBranding;
  }, [chatbot]);
  const languageCode = getLanguageCode(language);

  const [contact, updateScreen] = messengerStore((state) => [
    state.contact,
    state.updateScreen,
  ]);

  const customize = useMemo(
    () => chatbot?.customize as CustomizeData,
    [chatbot],
  );

  const settings = useMemo(
    () => chatbot?.settings as SettingsFormData,
    [chatbot],
  );

  const aiAvatar = customize?.theme?.aiAvatar;

  return (
    <>
      <div
        ref={conversationListRef}
        id="conversation-list-body"
        className="conversation-list-radius flex flex-grow flex-col overflow-y-auto bg-white"
      >
        {contact && contact?.chats && !!contact?.chats?.length ? (
          <ul>
            {contact?.chats.map((chat) => {
              const messages = [...(chat?.messages || [])].sort(
                (a, b) =>
                  dayjs(b.createdAt).valueOf() - dayjs(a.createdAt).valueOf(),
              );
              const lastMessage = messages?.[0];
              const isSeen = chat.contactUnreadChats?.length === 0;

              const sender = getMessageSenderName({
                message: lastMessage,
                target: 'USER',
                aiAgentName: settings?.aiName,
              });

              const isAnonymousUser =
                lastMessage?.sender === 'USER' &&
                !lastMessage?.agent?.avatar &&
                !lastMessage?.agent?.email;

              return (
                <li
                  className={cn(
                    'border-b border-[#EAEAEA] hover:bg-[#F3F4F6]',
                    {
                      ['font-semibold']: !isSeen,
                    },
                  )}
                  key={chat.id}
                >
                  <button
                    onClick={() => {
                      updateActiveChat(chat.id);
                      updateScreen('messenger');
                    }}
                    className={styles.chat_card}
                  >
                    {!isAnonymousUser ? (
                      <Avatar
                        size={28}
                        src={lastMessage?.agent?.avatar || aiAvatar}
                        email={lastMessage?.agent?.email}
                        defaultAIAvatar={
                          lastMessage?.sender === 'AI' && !aiAvatar
                        }
                      />
                    ) : null}

                    <div
                      className={cn('flex w-full flex-col items-start', {
                        ['ml-[10px]']: !isAnonymousUser,
                      })}
                    >
                      <p
                        className={cn(
                          'text-normal mb-1 line-clamp-1 max-w-[calc(100%-32px)] text-left text-sm',
                        )}
                      >
                        {getMessageType(
                          lastMessage?.text,
                          lastMessage?.type,
                        ) === MessageType.IMAGE
                          ? `${sender} sent an image`
                          : removeMd(lastMessage?.text)}
                      </p>

                      <div className="flex w-full max-w-[calc(100%-32px)] items-center gap-x-1 text-[10px] leading-[14px] text-[#777777]">
                        <span className="line-clamp-1 text-left capitalize">
                          {sender}
                        </span>

                        <span className="h-[1.5px] w-[1.5px] flex-none rounded-full bg-[#777777]" />

                        <span className="w-max flex-none">
                          {dayjs(lastMessage?.createdAt)
                            .locale(languageCode)
                            .fromNow()}
                        </span>
                      </div>
                    </div>
                    <div
                      id="select-chat-icon"
                      className={styles.chat_card_icon}
                    >
                      <ChevronRightIcon className="h-5 w-5" />
                    </div>
                  </button>
                </li>
              );
            })}
          </ul>
        ) : (
          <div
            id="conversation-list-empty"
            className="flex h-full flex-col items-center justify-center"
          >
            <span className="bg-bluesmart flex h-12 w-12 items-center justify-center rounded-full text-white">
              <AngularBubbleIcon width="24" height="24" />
            </span>
            <h5 className="mt-6 text-center text-[18px] font-semibold leading-5">
              No messages.
            </h5>
            <p className="mt-1.5 text-center text-[14px] font-normal leading-5">
              Send us a message.
            </p>
          </div>
        )}
      </div>
      <div id="messenger-bottom" className="sticky bottom-0 bg-white">
        <div className="mb-5 mt-5 flex w-full items-center justify-center">
          <MessengerButton
            backgroundColor={customize?.theme?.color?.userMessage}
            onClick={() => {
              updateActiveChat(null);
              updateScreen('messenger');
            }}
          >
            {getValue('newMessageBtn')}
            <PlaneIcon className="ml-2" />
          </MessengerButton>
        </div>
        <MessengerFooter
          removeBranding={customize?.theme?.removeBranding && !hasBranding}
        />
      </div>
    </>
  );
};

export default ConversationList;
