import type { PlatformFormData } from '@/app/(app-layout)/aibot/[chatbotId]/(layout)/customize/types';

import * as Events from '../events';
import { checkIsMobile } from './checkIsMobile';

const showIntercom = () => {
  if (
    window.hasOwnProperty('Intercom') &&
    typeof window?.Intercom === 'function'
  ) {
    window.Intercom('showNewMessage'); //Open Intercom messenger
    Events.publish('lc.hideMessenger'); //Close LiveChatAi messenger
    return;
  }

  console.warn('Intercom script not found!');
};

export const handleHumanRouting = async ({
  item,
  chatId,
}: {
  item: PlatformFormData;
  chatId: string;
}) => {
  const message = `Chat Id: ${chatId} \n`;

  switch (item.platform) {
    case 'intercom':
      showIntercom();
      break;
    case 'whatsapp':
      const isMobile = checkIsMobile();

      const webUrl = `https://web.whatsapp.com/send?phone=${
        item.whatsappNumber
      }&text=${encodeURI(message)}&app_absent=0`;

      const mobileUrl = `https://api.whatsapp.com/send?phone=${
        item.whatsappNumber
      }&text=${encodeURI(message)}`;

      window.open(isMobile ? mobileUrl : webUrl, '_blank');
      break;
    case 'line':
      window.open(`https://line.me/R/ti/p/${item.lineId}`, '_blank');
      break;
    case 'messenger':
      window.open(
        `http://m.me/${item.facebookPageId}?text=${encodeURI(message)}`,
        '_blank',
      );
      break;
    case 'sms':
      window.open(`sms://${item.phoneNumber}?&body=${encodeURI(message)}`);
      break;
    case 'telegram':
      const tgUsername = item.telegramUsername?.startsWith('@')
        ? item.telegramUsername.replace('@', '')
        : item.telegramUsername;

      window.open(`https://t.me/${tgUsername}`, '_blank');
      break;
    default:
      break;
  }
};
