import merge from 'lodash/merge';

import { initialCustomizeValue } from '@/app/(app-layout)/aibot/[chatbotId]/(layout)/customize/data';
import { CustomizeData } from '@/app/(app-layout)/aibot/[chatbotId]/(layout)/customize/types';

export const getChatbotCustomize = (
  customize?: CustomizeData,
): CustomizeData => {
  return merge({ ...initialCustomizeValue }, { ...(customize || {}) });
};
