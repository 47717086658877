import { APP_URL } from '@/config/dashboard';
import LocalStorage from '@/utils/local-storage';

export interface IdentityData {
  distinctId: string;
  email?: string;
  name?: string;
  phone?: string;
  attributes?: Record<string, string>;
}

export const handleIdentifyEvent = async (
  chatbotId: string,
  data?: IdentityData,
) => {
  const unIdentifiedId = LocalStorage.get(
    `live-chat-ai-unidentified-${chatbotId}`,
  );

  const distinctId = data?.distinctId;
  const isDistinctIdValid =
    distinctId &&
    distinctId.trim() !== '' &&
    distinctId !== 'undefined' &&
    distinctId !== 'null';

  if (!isDistinctIdValid) {
    // No distinctId, no need to identify
    LocalStorage.remove(`live-chat-ai-distinct-${chatbotId}`);

    if (unIdentifiedId) {
      // Reset to unidentified user
      LocalStorage.set(`live-chat-ai-${chatbotId}`, unIdentifiedId);
      LocalStorage.remove(`live-chat-ai-unidentified-${chatbotId}`);
    }

    return;
  }

  const contactId = LocalStorage.get(`live-chat-ai-${chatbotId}`);
  const localDistinctId = LocalStorage.get(
    `live-chat-ai-distinct-${chatbotId}`,
  );

  const req = await fetch(`${APP_URL}/api/aibot/contact/identify`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...data,
      ...(localDistinctId !== distinctId ? {} : { contactId }),
      chatbotId,
    }),
  });

  const response = await req.json();

  if (response?.status === 'success') {
    const updateId = response.contactId;

    // eslint-disable-next-line no-console
    console.log('Identify Done', data);

    if (updateId !== contactId) {
      // In the unidentified key we need to keep the user's contactIds that are not indentified.
      // if localDistinctId is not set, it means the user is first time identified.
      if (!localDistinctId) {
        LocalStorage.set(`live-chat-ai-unidentified-${chatbotId}`, contactId);
      }

      LocalStorage.set(`live-chat-ai-${chatbotId}`, updateId);
    }

    //set new distinctId to local storage
    LocalStorage.set(`live-chat-ai-distinct-${chatbotId}`, distinctId);
  } else {
    throw new Error('Failed to identify user');
  }
};
